<template>
  <b-card-code
    no-body
    title="Auction Transaction History"
  >

    <div class="dt_adv_search ml-1 mb-1 mr-1">
      <div class="row">

        <div class="col-md-3 mt-1">
          <input
            v-model="filter.ta_number"
            type="text"
            placeholder="Search by TA Number..."
            class="form-control form-control"
          >
        </div>

        <div class="col-md-2 mt-1">
          <select
            v-model="filter.status"
            class="form-control form-control"
          >
            <option value="">
              -- All Status --
            </option>
            <option value="pending">
              Pending
            </option>
            <option value="success">
              Success
            </option>
            <option value="failed">
              Failed
            </option>
            <option value="expired">
              Expired
            </option>
          </select>
        </div>

        <div class="col-md-2 mt-1">
          <select
            v-model="filter.type"
            class="form-control form-control"
          >
            <option value="">
              -- All Type --
            </option>
            <option value="bid">
              Bid
            </option>
            <option value="ask">
              Ask
            </option>
          </select>
        </div>

        <div class="col-md-3 mt-1">
          <select
            v-model="filter.commodity_slug"
            class="form-control form-control"
          >
            <option
              selected
              value=""
            >
              -- All Commodity --
            </option>
            <option
              v-for="commodity in commodities"
              :key="commodity.slug"
              :value="commodity.slug"
            >
              {{ commodity.name }}
            </option>
          </select>
        </div>

        <div class="col-md-2 mt-1">
          <select
            v-model="filter.warehouse_slug"
            class="form-control form-control"
          >
            <option
              selected
              value=""
            >
              -- All Depository --
            </option>
            <option
              v-for="warehouse in warehouses"
              :key="warehouse.slug"
              :value="warehouse.slug"
            >
              {{ warehouse.name }}
            </option>
          </select>
        </div>

        <div class="col-md-2 mt-1">
          <v-select
            v-model="filter.user_id"
            :options="users"
            :reduce="option => option.id"
            placeholder="-- Select user --"
            @search="onSearchUser"
          >
            <template slot="no-options">
              Type user name, code, or email..
            </template>
          </v-select>
        </div>

        <div class="col-md-3 mt-1">
          <date-picker
            v-model="filter.date"
            value-type="YYYY-MM-DD"
            placeholder="Select date"
          />
        </div>

      </div>
    </div>

    <div class="table-responsive">
      <table class="table b-table">
        <thead>
          <tr>
            <th class="pl-1">
              ID
            </th>
            <th class="pl-0">
              Type
            </th>
            <th class="pl-0">
              User
            </th>
            <th class="pl-0">
              Auction
            </th>
            <th class="pl-0">
              TA Number
            </th>
            <th
              class="pl-0"
              style="cursor: pointer;"
              @click="sortBy('created_at')"
            >
              Time
              <span v-if="filter.sort_by == 'created_at'">
                <svg
                  v-if="filter.sort_type == 'asc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
                <svg
                  v-if="filter.sort_type == 'desc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </span>
            </th>
            <th
              class="pl-0"
              style="cursor: pointer; min-width: 40px;"
              @click="sortBy('price')"
            >
              Order Price
              <span v-if="filter.sort_by == 'price'">
                <svg
                  v-if="filter.sort_type == 'asc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
                <svg
                  v-if="filter.sort_type == 'desc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </span>
            </th>
            <th
              class="pl-0"
              style="cursor: pointer; min-width: 40px;"
              @click="sortBy('fixed_price')"
            >
              Match Price
              <span v-if="filter.sort_by == 'fixed_price'">
                <svg
                  v-if="filter.sort_type == 'asc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
                <svg
                  v-if="filter.sort_type == 'desc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </span>
            </th>
            <th
              class="pl-0"
              style="cursor: pointer;"
              @click="sortBy('lot')"
            >
              Lot
              <span v-if="filter.sort_by == 'lot'">
                <svg
                  v-if="filter.sort_type == 'asc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
                <svg
                  v-if="filter.sort_type == 'desc'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </span>
            </th>
            <th class="pl-0">
              Status
            </th>
            <th class="pl-0" />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="9"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.slug"
          >
            <td
              class="pl-1"
              style="text-transform: capitalize;"
            >
              {{ item.id }}
            </td>
            <td
              class="pl-0"
              style="text-transform: capitalize;"
            >
              {{ item.type }}
            </td>
            <td
              v-if="item.user != null"
              class="pl-0"
            >
              <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                {{ item.user.code }}<br><small>{{ item.user.email }}</small>
              </router-link>
            </td><td v-else>
              -
            </td>
            <td class="pl-0">
              {{ item.auction.commodity.code }}<br><small>{{ item.auction.warehouse.name }}</small>
            </td>
            <td class="pl-0">
              {{ item.trade_allocations.map(item => item.ta_number).join(', ') || '-' }}
            </td>
            <td class="pl-0">
              <small>{{ item.time }}</small>
            </td>
            <td class="pl-0">
              {{ item.price }}
            </td>
            <td class="pl-0">
              {{ item.lot_complete > 0 ? item.fixed_price : '-' }}
            </td>
            <td class="pl-0">
              {{ item.lot }} lot
            </td>
            <td
              class="pl-0"
              style="text-transform: capitalize;"
            >
              <span v-if="item.failed_soon_by == null">{{ item.status }}</span>
              <br>
              <small v-if="item.lot_complete > 0">
                {{ item.lot_complete >= item.lot ? 'Fill' : 'PartFill' }} {{ item.lot_complete + '/' + item.lot }}
              </small><br>
              <span
                v-if="item.failed_soon_by != null"
                class="badge badge-danger"
                style="margin-bottom: 2px;"
              >Failed, Need Action</span>
              <b-dropdown
                v-if="item.failed_soon_by != null && checkPermission('action failed settlement')"
                variant="danger"
                split
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item @click="failedAction(item.uuid, 'approve')">
                  Approve Failed Settlement
                </b-dropdown-item>
                <b-dropdown-item @click="failedAction(item.uuid, 'resettlement')">
                  Resettlement
                </b-dropdown-item>
              </b-dropdown>
            </td>
            <td class="pl-0">
              <b-dropdown
                v-if="checkPermission('show auctions') ||checkPermission('show auction transaction detail') || checkPermission('cancel auction transaction')"
                variant="success"
                size="sm"
              >
                <template #button-content>
                  <eye-icon
                    size="1x"
                    class="custom-class"
                  /> Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('show auction transaction detail')"
                  @click="goToDetailTransaction(item.uuid)"
                >
                  Detail Transaction
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('cancel auction transaction')"
                  @click="cancelTransaction(item)"
                >
                  Cancel Transaction
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('show auctions')"
                  @click="goToDetailAuction(item.auction.uuid)"
                >
                  Detail Auction
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="10"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { EyeIcon } from 'vue-feather-icons'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import axios from 'axios'

export default {
  title() {
    return `Auction Transaction History`
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  components: {
    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardCode,
    EyeIcon,
    flatPickr,
    BSpinner,
    vSelect,
    DatePicker,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 100),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        date: null,
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        type: '',
        user_id: '',
        commodity_slug: '',
        warehouse_slug: '',
        sort_by: null,
        sort_type: null,
      },
      isLoading: false,
      warehouses: [],
      commodities: [],
      users: [],
      requests: [],
      request: null,
    }
  },
  mounted() {
    // var context = this
    // this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
    //   if (payload.type == 'auction-transaction') {
    //     context.getData(context.currentPage || 1);
    //   }
    // });
  },
  created() {
    this.getData()
    this.loadFormData()
    this.executeSearch('')
  },
  methods: {
    loadFormData() {
      this.$http.get('/public/commodities/warehouses', { useCache: true })
        .then(response => {
          this.warehouses = response.data.data
        })
      this.$http.get('/public/commodities/', { useCache: true })
        .then(response => {
          const context = this
          this.commodities = response.data.data
        })
    },
    sortBy(by) {
      if (this.filter.sort_by != by) {
        this.filter.sort_type = null
      }
      this.filter.sort_by = by

      if (this.filter.sort_type == null) {
        this.filter.sort_type = 'asc'
      } else if (this.filter.sort_type == 'asc') {
        this.filter.sort_type = 'desc'
      } else {
        this.filter.sort_type = null
      }

      this.getData()
    },
    cancel() {
      this.request.cancel()
      this.clearOldRequest('Cancelled')
    },
    logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log('Request cancelled')
      }
    },
    clearOldRequest(msg) {
      this.request.msg = msg
      this.requests.push(this.request)
      this.request = null
    },
    getData(page = 1) {
      // this.isLoading = true;
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      if (this.request) this.cancel();

      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      this.$http.get('/admin/auction-transactions', {
        params: queryParams,
        cancelToken: axiosSource.token,
      })
        .then(response => {
          this.result = response.data.data
        // this.isLoading = false;
        })
    },
    goToDetailAuction(uuid) {
      this.$router.push({ name: 'auctions.show', params: { uuid } })
    },
    goToDetailTransaction(uuid) {
      this.$router.push({ name: 'auctions.transactions.show', params: { uuid } })
    },
    failedAction(uuid, type) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure to ${type} this failed transaction?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`/admin/auction-transactions/${uuid}/action-failed-transaction`, {
            type,
          })
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `${type} success`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    cancelTransaction(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to cancel this transaction?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`/admin/auction-transactions/${item.uuid}/cancel`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `${type} success`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    executeSearch(query) {
      const vm = this
      this.$http.get(`/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=${encodeURI(query)}`, { useCache: true })
        .then(response => {
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              id: user.id,
              label: `${user.code} (${user.name})`,
            })
          })
        })
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(encodeURI(search))
      loading(false)
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
